<template>
  <div>
    <Breadcrumbs page_title="course" :items="breadcrumbs" />

    <v-container fluid>
      <v-row v-if="loading" class="mt-5">
        <v-col cols="2" v-for="n in 4" :key="n">
          <v-skeleton-loader
            class="mx-auto"
            max-height="180"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else class="mt-5">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="2"
          v-for="(item, i) in cardItems"
          :key="i"
        >
          <v-card
            style="border-radius: 16px"
            :style="[
              item.selected == true
                ? { border: '2px solid ' + item.bgcolor }
                : {},
            ]"
            height="200"
            @click="selectCourseCategory(item)"
          >
            <v-btn
              :style="[{ border: '3px solid ' + item.bgcolor }]"
              fab
              top
              x-small
              right
              absolute
              class="countIconBtn"
            >
              <span
                style="font-size: 14px; font-weight: bold; color: #424242"
                >{{ item.courseCount }}</span
              >
            </v-btn>

            <v-card-text justify="center" align="center" style="height: 100%">
              <div
                class="imgBg"
                :style="[
                  item.bgcolor
                    ? { 'background-color': item.bgcolor }
                    : { 'background-color': '#a6cc39' },
                ]"
              ></div>
              <v-img :src="item.image" width="52" class="courseImg"></v-img>
              <p
                class="text-center mt-5"
                style="font-size: 18px; color: #424242"
              >
                {{ $t(item.title) }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="showCourseList">
        <v-col cols="12" md="8" lg="8">
          <v-card
            style="
              width: 1314px;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #0000000f;
              border-radius: 16px;
            "
          >
            <v-text-field
              outlined
              dense
              hide-details
              style="
                border-radius: 8px;
                border-color: #c0c0c0;
                background-color: #42424214;
              "
              clearable
              placeholder="search..."
              v-model="search"
              class="mb-4"
            >
            </v-text-field>
            <v-list>
              <v-list-group
                active-class="primary--text"
                style="margin-bottom: 0.7rem"
                class="list-Bg"
                v-for="item in searchInLists"
                :key="item.assignmentCourseID"
                @click="getCourseDetailFiles(item.assignmentCourseID)"
                append-icon
                no-action
                :value="item.isOpen"
              >
                <template v-slot:activator>
                  <v-icon slot="prependIcon" color="#424242"
                    >mdi-chevron-right</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title
                      class="ml-4"
                      style="
                        font-size: 18px !important;
                        color: #424242 !important;
                      "
                      >{{ item.courseName }}</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn
                      :style="[{ border: '3px solid' + selectedColor }]"
                      fab
                      top
                      x-small
                      right
                      absolute
                      style="
                        box-shadow: unset;
                        top: 16px;
                        background-color: #ffffff;
                        margin-right: 55px;
                      "
                      class="listCountIcon"
                    >
                      <span
                        style="
                          font-size: 14px;
                          font-weight: bold;
                          color: #424242;
                        "
                        >{{ item.fileTotal }}</span
                      >
                    </v-btn>
                  </v-list-item-icon>
                </template>
                <div style="background-color: #42424214; padding-bottom: 20px">
                  <v-row v-show="pretestflag" class="ml-11 mr-15">
                    <v-col cols="6" md="6" lg="6" sm="6">
                      <p
                        class="text-left mt-3"
                        style="font-size: 18px; color: #424242"
                      >
                        Pre-Test
                      </p>
                    </v-col>
                    <v-col cols="6" lg="6" sm="6" md="6">
                      <p
                        style="font-size: 18px; color: #424242; text-align: end"
                      >
                        Description : {{ item.description }}
                      </p>
                    </v-col>
                  </v-row>
                  <div class="ml-10 mr-10">
                    <v-card
                      style="border-radius: 16px"
                      v-show="pretestflag"
                      height="98"
                      width="1204px"
                      @click="
                        clickPreTest(PreTestdata, item.assignmentCourseID)
                      "
                    >
                      <template>
                        <v-row rows="12" lg="12" md="12" sm="12">
                          <v-col cols="3" lg="3" md="3" class="mb-1">
                            <v-img
                              src="@/assets/images/pretesticon_0.png"
                              class="detail-child-img"
                              style="border-radius: 16px"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            lg="6"
                            style="display: flex; align-items: center"
                          >
                            <p
                              class="text-left mt-1"
                              style="font-size: 18px; color: #424242"
                            >
                              {{ PreTestdata.quizName }} <br />
                              you have 1 Quiz
                            </p>
                          </v-col>
                          <v-col
                            cols="3"
                            md="3"
                            lg="3"
                            class="mt-5"
                            style="display: flex; justify-content: flex-end"
                          >
                            <div style="padding-right: 20px">
                              <v-img
                                v-if="
                                  PreTestdata.type == 'quiz' &&
                                  PreTestdata.completed == false
                                "
                                src="@/assets/images/quiz_NS.svg"
                                width="33px"
                                height="33px"
                                style="margin-right: 2px !important"
                              ></v-img>
                              <v-img
                                v-if="
                                  PreTestdata.type == 'preQuiz' &&
                                  PreTestdata.completed == false
                                "
                                src="@/assets/images/quiz_NS.svg"
                                width="33px"
                                height="33px"
                                style="margin-right: 2px !important"
                              ></v-img>

                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PreTestdata.type == 'preQuiz' &&
                                  PreTestdata.completed == true &&
                                  PreTestdata.completedCount != 0
                                "
                                slot="prependIcon"
                                color="#4FB14E"
                                >mdi-check-circle</v-icon
                              >
                              <!-- selectedColor -->
                              <!-- Completed but fail quiz -->

                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PreTestdata.type == 'preQuiz' &&
                                  PreTestdata.completed == true &&
                                  PreTestdata.completedCount == 0
                                "
                                slot="prependIcon"
                                color="#D52A2A"
                                >mdi-check-circle</v-icon
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card>
                  </div>

                  <div class="mt-5" v-show="articleflag">
                    <p
                      class="text-left mt-3 ml-15"
                      style="font-size: 18px; color: #424242"
                    >
                      Article
                    </p>
                  </div>
                  <div class="ml-10 mr-10">
                    <v-card
                      style="border-radius: 16px"
                      v-show="articleflag"
                      height="98"
                      width="1204px"
                      @click="clickArticle(Articledata)"
                    >
                      <template>
                        <v-row>
                          <v-col cols="3" md="3" lg="3" style="justify: center">
                            <v-img
                              src="@/assets/images/article_c.svg"
                              class="detail-child-img"
                              style="border-radius: 16px"
                            ></v-img>
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            lg="6"
                            style="display: flex; align-items: center"
                          >
                            <p
                              class="text-left mt-1"
                              style="font-size: 18px; color: #424242"
                            >
                              {{ Articledata.fileName }}
                            </p>
                          </v-col>
                          <v-col
                            cols="3"
                            md="3"
                            lg="3"
                            class="mt-5"
                            style="display: flex; justify-content: flex-end"
                          >
                            <div
                              style="margin-right: 20px"
                              v-if="Articledata.type == 'article'"
                              class="article-container"
                            >
                              <v-img
                                v-if="
                                  Articledata.type == 'article' &&
                                  !Articledata.isCompleted
                                "
                                src="@/assets/images/articleNotStarted.svg"
                                width="32px"
                                height="32px"
                              ></v-img>
                              <v-img
                                v-if="
                                  Articledata.type == 'article' &&
                                  Articledata.isCompleted
                                "
                                src="@/assets/images/articleComplete.svg"
                                width="32px"
                                height="32px"
                              ></v-img>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card>
                  </div>

                  <div class="mt-5">
                    <p
                      class="text-left mt-3 ml-15"
                      style="font-size: 18px; color: #424242"
                    >
                      Video
                    </p>
                  </div>
                  <v-row>
                    <v-col
                      v-for="(dataItem, index) in courseChildItems"
                      :key="index"
                      cols="12"
                      @click="checkContentType(dataItem)"
                    >
                      <div class="ml-10 mr-10">
                        <v-card
                          style="border-radius: 16px; cursor: pointer"
                          height="98"
                          width="1204px"
                        >
                          <template>
                            <v-row>
                              <v-col cols="3" md="3" lg="3" mt-3>
                                <v-img
                                  :src="dataItem.pictureBackground"
                                  class="detail-child-img"
                                  style="border-radius: 16px"
                                ></v-img>
                              </v-col>
                              <v-col
                                cols="6"
                                md="6"
                                lg="6"
                                style="display: flex; align-items: center"
                              >
                                <p
                                  class="text-left mt-1"
                                  style="font-size: 18px; color: #424242"
                                >
                                  {{ dataItem.title }}
                                </p>
                              </v-col>
                              <v-col
                                cols="3"
                                md="3"
                                lg="3"
                                class="mt-5"
                                style="display: flex; justify-content: flex-end"
                              >
                                <div style="padding-right: 20px">
                                  <v-icon
                                    v-if="
                                      dataItem.type == 'video' &&
                                      dataItem.isComplete == null &&
                                      dataItem.progress == null
                                    "
                                    style="font-size: 37px !important"
                                    slot="prependIcon"
                                    color="#008AFF"
                                    >mdi-play-circle</v-icon
                                  >
                                  <!-- In Progres -->
                                  <v-icon
                                    v-if="
                                      dataItem.type == 'video' &&
                                      dataItem.isComplete == false &&
                                      dataItem.progress != null
                                    "
                                    style="font-size: 37px !important"
                                    slot="prependIcon"
                                    color="#E0BF0B"
                                    >mdi-play-circle</v-icon
                                  >
                                  <!-- Complete -->
                                  <v-icon
                                    v-if="
                                      dataItem.type == 'video' &&
                                      dataItem.isComplete == true
                                    "
                                    style="font-size: 37px !important"
                                    slot="prependIcon"
                                    color="#4FB14E"
                                    >mdi-check-circle</v-icon
                                  >
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>

                  <div class="mt-5" v-show="posttestflag">
                    <p
                      class="text-left mt-3 ml-15"
                      style="font-size: 18px; color: #424242"
                    >
                      Post-Test
                    </p>
                  </div>
                  <div class="ml-10 mr-10">
                    <v-card
                      style="border-radius: 16px"
                      v-show="posttestflag"
                      height="98"
                      width="1204px"
                      @click="
                        clickPostTest(PostTestdata, item.assignmentCourseID)
                      "
                    >
                      <template>
                        <v-row>
                          <v-col cols="3" md="3" lg="3" mt-3>
                            <v-img
                              v-if="
                                PostTestdata.type === 'quiz' ||
                                PostTestdata.type === 'preQuiz'
                              "
                              src="@/assets/images/posttesticon_0.png"
                              class="detail-child-img"
                              style="border-radius: 16px"
                            ></v-img>
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            lg="6"
                            style="display: flex; align-items: center"
                          >
                            <p
                              class="text-left mt-1"
                              style="font-size: 18px; color: #424242"
                            >
                              {{ PostTestdata.quizName }}<br />
                              You have 1 Quiz
                            </p>
                          </v-col>
                          <v-col
                            cols="3"
                            md="3"
                            lg="3"
                            class="mt-5"
                            style="display: flex; justify-content: flex-end"
                          >
                            <div style="padding-right: 20px">
                              <v-img
                                v-if="
                                  PostTestdata.type == 'quiz' &&
                                  PostTestdata.completed == false
                                "
                                src="@/assets/images/quiz_NS.svg"
                                width="33px"
                                height="33px"
                                style="margin-right: 2px !important"
                              ></v-img>
                              <v-img
                                v-if="
                                  PostTestdata.type == 'preQuiz' &&
                                  PostTestdata.completed == false
                                "
                                src="@/assets/images/quiz_NS.svg"
                                width="33px"
                                height="33px"
                                style="margin-right: 2px !important"
                              ></v-img>

                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PostTestdata.type == 'quiz' &&
                                  PostTestdata.completed == true &&
                                  PostTestdata.completedCount != 0
                                "
                                slot="prependIcon"
                                color="#4FB14E"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PostTestdata.type == 'preQuiz' &&
                                  PostTestdata.completed == true &&
                                  PostTestdata.completedCount != 0
                                "
                                slot="prependIcon"
                                color="#4FB14E"
                                >mdi-check-circle</v-icon
                              >
                              <!-- selectedColor -->
                              <!-- Completed but fail quiz -->
                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PostTestdata.type == 'quiz' &&
                                  PostTestdata.completed == true &&
                                  PostTestdata.completedCount == 0
                                "
                                slot="prependIcon"
                                color="#D52A2A"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                style="font-size: 37px !important"
                                v-if="
                                  PostTestdata.type == 'preQuiz' &&
                                  PostTestdata.completed == true &&
                                  PostTestdata.completedCount == 0
                                "
                                slot="prependIcon"
                                color="#D52A2A"
                                >mdi-check-circle</v-icon
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card>
                  </div>
                </div>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="showVideoDialog = false"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      @updateProgressCircle="updateProgressCircle"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :lastWatchedTime="lastWatchedTime"
      :popupTime="popupTime"
      :formattedDateTime="formattedDateTime"
      :memberID="memberID"
      :video_page="2"
      :viewerFlag="viewerFlag"
    ></videoPlay>

    <v-dialog width="470" v-model="quizShow" persistent>
      <v-card
        style="overflow: hidden !important; border-radius: 16px !important"
      >
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row class="mt-1 mb-1">
            <v-col style="text-align: center">
              <p class="mb-2">{{ $t(quizShowText) }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click="quizShow = false"
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px"
            class="text-capitalize white--text mb-2 mt-2 login-phone"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import videoPlay from "../Library/_subLibrary/_path/_video/videoPlay.vue";
export default {
  components: {
    videoPlay,
  },
  data() {
    return {
      search: "",
      searchtype: "",
      courseCategoryName: null,
      courseTemp: {},
      rq_courseId: 0,
      loading: true,
      quizShowText: null,
      quizShow: false,
      prequizCompleted: true,
      //video dialog data
      popupTime: 0,
      videoDescription: "",
      lastWatchedTime: "",
      videoName: "",
      contentId: 0,
      video_File: "",
      courseID: 0,
      memberID: "",
      //...................
      videoPlayerDialog: false,
      showVideoDialog: false,
      //...end of video dialog data
      //Videolink: "",
      //videoDialog: false,
      notStartedCourseID: [],
      inProgressCourseID: [],
      completedCourseID: [],
      delayCourseID: [],
      showCourseList: false,
      selectedColor: null,
      tempCourseItems: [], //raw uncategorize(not started, inprogress, etc...) courses
      courseChildItems: [],
      courseItems: [],
      viewerFlag:false,
      breadcrumbs: [
        {
          text: "course",
        },
      ],
      cardItems: [
        {
          bgcolor: "#008AFF",
          image: require("@/assets/images/notStarted.svg"),
          title: "notstarted",
          name: "notstarted",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#E0BF0B",
          image: require("@/assets/images/inProgress.svg"),
          title: "inprogress",
          name: "inprogress",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#4FB14E",
          image: require("@/assets/images/complete.svg"),
          title: "complete",
          name: "complete",
          selected: false,
          courseCount: 0,
        },
        {
          bgcolor: "#D52A2A",
          image: require("@/assets/images/delay.svg"),
          title: "delay",
          name: "delay",
          selected: false,
          courseCount: 0,
        },
      ],
      pretestflag: false,
      PreTestdata: {},
      posttestflag: false,
      PostTestdata: {},
      articleflag: false,
      Articledata: {},
      formattedDateTime: "",
    };
  },
  computed: {
    // filteredCourseItems() {
    //   let searchTerm = this.search.toLowerCase();
    //   return this.courseItems.filter((courseItem) => {
    //     // Customize this condition based on your search criteria
    //     return courseItem.name.toLowerCase().includes(searchTerm);
    //   });
    // },
    searchInLists() {
      if (this.search) {
        let searchItems = [];
        searchItems = this.courseItems.filter((x) =>
          x.courseName.toLowerCase().includes(this.search.toLowerCase())
        );
        return searchItems;
      } else {
        return this.courseItems;
      }
    },
  },
  mounted() {
    (async () => {
      await this.getProgressCirleCourseData();
      await this.getProgressCountName();
      this.getNotificationCount();
      this.getFavourite();

      if (this.$route.params.reQuiz == "requiz") {
        this.loading = true;
        this.selectCourseCategory(this.$route.params.courseTemp);
      }

      return Promise.resolve();
    })().then(() => {
      this.mountDone = true;
    });
  },
  methods: {
    updateProgressCircle() {
      switch (this.courseCategoryName) {
        case "notstarted":
          this.showCourseList = false;
          this.updateCourseCategoryMethodCall();
          break;
        case "inprogress":
          this.getCourseDetailFiles(this.courseID);
          break;
        case "delay":
          this.getCourseDetailFiles(this.courseID);
          break;
      }
    },
    formatDateTime(dateTime) {
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      };
      const dateTimeString = dateTime.toLocaleDateString("en-US", options);
      return dateTimeString.replace(/\//g, "-");
    },
    switchVideoCase(item) {
      if (this.prequizCompleted === true) {
        let date1 = new Date();
        this.formattedDateTime = this.formatDateTime(date1);
        this.showVideoDialog = true;
        this.memberID = item.memberID;
        // this.$refs.videoPlay.GetcommendData(item.videoID, 2);
        this.$refs.videoPlay.GetCommentsForVideo(item.videoID);
        this.video_File = item.videoFile;
        this.contentId = item.videoID;
        this.videoName = item.videoName;
        this.viewerFlag = item.viewerFlag;
        if (item.progress == "1" || item.progress == "1.00") {
          this.lastWatchedTime = 0;
        } else {
          this.lastWatchedTime = item.lastViewTime;
        }
        this.courseID = item.assignmentCourseID;
        this.popupTime = item.popupTime * 60; //change mins to seconds
      } else {
        this.quizShowText = "lblpretestalert";
        this.quizShow = true;
      }
    },
    clickPostTest(item, courseid) {
      if (item.fullLimit !== false) {
        this.quizShowText = "quizfulllimittext";
        this.quizShow = true;
        return false;
      } else if (item.canDoInprogress !== false) {
        this.quizShowText = "quizcandoinprogresstext";
        this.quizShow = true;
        return false;
      } else if (item.canDoWatchFinish !== false) {
        this.quizShowText = "quizfinishwatchtext";
        this.quizShow = true;
        return false;
      } else {
        this.$router.push({
          name: "course-quiz",
          query: {
            quizId: item.quizId,
            rq_courseId: courseid,
            courseTemp: this.courseTemp,
            quizIsPassed: item.completedCount, // 1 = pass 1 time, 2 = pass 2 times
            quizTypeID: item.quizTypeID,
          },
        });
      }
    },
    clickArticle(item) {
      if (this.prequizCompleted === true) {
        this.$router.push({
          name: "course-article",
          query: {
            assignmentArticleID: item.assignmentArticleID,
            courseID: item.courseID,
            memberID: item.memberID
          },
        });
      } else {
        this.quizShowText = "lblpretestalert";
        this.quizShow = true;
      }
    },
    checkContentType(item) {
      this.switchVideoCase(item);
    },
    clickPreTest(item, courseid) {
      let self = this;
      if (item.fullLimit !== false) {
        self.quizShowText = "quizfulllimittext";
        self.quizShow = true;
        return false;
      } else {
        self.$router.push({
          name: "course-quiz",
          query: {
            quizId: item.quizId,
            rq_courseId: courseid,
            courseTemp: self.courseTemp,
            quizIsPassed: item.completedCount, // 1 = pass 1 time, 2 = pass 2 times
            quizTypeID: item.quizTypeID, //pre-test = 2, post-test = 1
          },
        });
      }
    },

    closeDialog() {
      this.showVideoDialog = false;
      this.$refs.videoPlayer.pause();
    },

    async getCourseDetailFiles(course_id) {
      let self = this;
      self.courseChildItems = [];
      self.pretestflag = false;
      self.PreTestdata = {};
      self.posttestflag = false;
      self.PostTestdata = {};
      self.articleflag = false;
      self.Articledata = {};
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
        courseId: course_id,
      };
      await self.$axios
        .post(`${self.web_urlV6}Contents/GetAssignmentVideoList`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            let courseVideoFiles = res.data.data.progressData.map((v) => ({
              ...v,
              fileName: v.videoName,
              type: "video",
              title: v.title,
            }));

            if (res.data.data.article) {
              self.articleflag = true;
              self.Articledata = {
                fileName: res.data.data.article.title,
                type: "article",
                assignmentArticleID: res.data.data.article.assignmentArticleID,
                courseID: res.data.data.article.courseID,
                isCompleted: res.data.data.article.isCompleted,
                optimizedLink: res.data.data.article.optimizedLink,
                memberID: courseVideoFiles[0]?.memberID,
              };
            }

            if (res.data.data.quiz) {
              self.posttestflag = true;
              self.PostTestdata = {
                fileName: "Post-Quiz",
                type: "quiz",
                canDoInprogress: res.data.data.quiz.canDoInprogress, // || null
                canDoWatchFinish: res.data.data.quiz.canDoWatchFinish,
                completed: res.data.data.quiz.completed,
                completedCount: res.data.data.quiz.completedCount,
                fullLimit: res.data.data.quiz.fullLimit,
                quizId: res.data.data.quiz.quizId,
                quizTypeID: 1,
                quizName: res.data.data.quiz.quizName,
              };
            }
            if (res.data.data.preQuiz) {
              self.pretestflag = true;
              self.prequizCompleted = res.data.data.preQuiz.completed;
              self.PreTestdata = {
                fileName: "Pre-Quiz",
                type: "preQuiz",
                canDoInprogress: res.data.data.preQuiz.canDoInprogress, // || null
                canDoWatchFinish: res.data.data.preQuiz.canDoWatchFinish,
                completed: res.data.data.preQuiz.completed,
                completedCount: res.data.data.preQuiz.completedCount,
                fullLimit: res.data.data.preQuiz.fullLimit,
                quizId: res.data.data.preQuiz.quizId,
                quizTypeID: 2,
                quizName: res.data.data.preQuiz.quizName,
              };
            } else {
              self.prequizCompleted = true;
            }

            self.courseChildItems = courseVideoFiles;

            self.rq_courseId = course_id;

            self.checkCourseComplete(res.data.data);
          }
        });
    },

    checkCourseComplete(responseData) {
      let self = this;
      let allVideoIsCompleted = null;
      let quizIsCompleted = null;
      let prequizIsCompleted = null;
      let articleIsCompleted = null;

      if (self.courseCategoryName != "complete") {
        allVideoIsCompleted = responseData.progressData.find(
          (j) => j.isComplete === false || j.isComplete == null
        )
          ? "false"
          : "true";
        articleIsCompleted =
          responseData.article !== null
            ? responseData.article.isCompleted
            : true;
        quizIsCompleted =
          responseData.quiz != null ? responseData.quiz.completed : true;
        prequizIsCompleted =
          responseData.preQuiz != null ? responseData.preQuiz.completed : true;

        if (
          prequizIsCompleted === true &&
          allVideoIsCompleted === "true" &&
          quizIsCompleted === true &&
          articleIsCompleted === true
        ) {
          self.showCourseList = false;
          self.updateCourseCategoryMethodCall();
        }
      }
    },

    async updateCourseCategoryMethodCall() {
      this.loading = true;
      await this.getProgressCirleCourseData();
      await this.getProgressCountName();
    },

    async getProgressCountName() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_urlV6}Contents/GetProgressCountName`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.tempCourseItems = res.data.data.map((v) => ({
              ...v,
              fileTotal:
                v.assignmentQuizCount +
                v.assignmentVideoCount +
                v.assignmentArticleCount,
              isOpen: false,
            }));
            self.loading = false;
          }
        });
    },

    async getProgressCirleCourseData() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_urlV4}Contents/GetUserProgressMobileFrist`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.cardItems.forEach((value) => {
              if (value.name == "notstarted") {
                value.courseCount = res.data.data.notStarted.length;
              } else if (value.name == "inprogress") {
                value.courseCount = res.data.data.inProgress.length;
              } else if (value.name == "complete") {
                value.courseCount = res.data.data.completed.length;
              } else {
                value.courseCount = res.data.data.delay.length;
              }
            });
            self.notStartedCourseID = res.data.data.notStarted;
            self.inProgressCourseID = res.data.data.inProgress;
            self.completedCourseID = res.data.data.completed;
            self.delayCourseID = res.data.data.delay;
          }
        });
    },

    selectCourseCategory(v) {
      this.showCourseList = v.courseCount != 0 ? !v.selected : false;
      this.cardItems.forEach((value) => {
        if (value.name == v.name) {
          value.selected = !value.selected;
        } else {
          value.selected = false;
        }
      });
      this.selectedColor = v.bgcolor;

      if (this.showCourseList === true) {
        let rawData = this.tempCourseItems;

        let categorized = [];

        switch (v.name) {
          case "notstarted":
            categorized = rawData.filter((elem) => {
              return this.notStartedCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "inprogress":
            categorized = rawData.filter((elem) => {
              return this.inProgressCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "complete":
            categorized = rawData.filter((elem) => {
              return this.completedCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
          case "delay":
            categorized = rawData.filter((elem) => {
              return this.delayCourseID.some((ele) => {
                return ele.assignmentCourseID === elem.assignmentCourseID;
              });
            });
            break;
        }
        this.courseCategoryName = v.name;

        this.courseTemp = {
          bgcolor: v.bgcolor,
          courseCount: v.courseCount,
          image: v.image,
          name: v.name,
          selected: false,
          title: v.title,
        };

        if (this.$route.params.reQuiz == "requiz") {
          this.getCourseDetailFiles(this.$route.params.rq_courseId);
          this.courseItems = categorized.map((x) => ({
            ...x,
            isOpen: this.$route.params.rq_courseId == x.assignmentCourseID,
          }));

          this.loading = false;
          this.$route.params.reQuiz = null;
        } else {
          this.courseItems = categorized.map((x) => ({
            ...x,
            isOpen: false,
          }));
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep
  .list-Bg
  .v-list-group__header.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 70px;
  border-radius: 16px;
}

::v-deep
  .list-Bg
  .v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 70px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.card-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line if needed */
  justify-content: space-between;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  background-color: #42424208;
}

.courseImg {
  position: absolute;
  top: 40%;
  left: 48%;
  transform: translate(-50%, -48%);
}
.imgBg {
  height: 130px;
  opacity: 0.1;
  border-radius: 8px;
  position: relative;
  z-index: 0 !important;
}
.countIconBtn {
  background-color: #ffffff;
  border-radius: 50px !important;
  box-shadow: unset;
}
::v-deep .primary--text {
  color: #424242 !important;
  caret-color: #424242 !important;
}
::v-deep .countIconBtn.theme--light.v-btn {
  color: #ffffff;
}
::v-deep .countIconBtn.theme--light.v-btn.v-btn--has-bg {
  background-color: #ffffff;
}
::v-deep .countIconBtn.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: -10px;
}
::v-deep .countIconBtn.v-btn--fab.v-size--x-small {
  height: 42px;
  width: 42px;
}
::v-deep .countIconBtn.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -8px;
}
</style>

<style scoped>
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
/* Handle */
::v-deep .overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .overflow-y-auto.v-sheet.theme--light {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
}
::v-deep .v-list {
  padding: 0;
}
.detail-child-img {
  width: 156px;
  height: 79px;
  margin-left: 10px;
  border-radius: 10px;
}
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 50px;
}
.v-list-item__icon {
  align-self: flex-start;
  margin: 10px 0;
}
.article-container {
  width: 35px !important;
  height: 35px !important;
}
::v-deep .v-image__image--cover {
  background-size: contain !important;
}
</style>
